export default {
  state: {
    showOverlay: false,
    overlayMessage: "",
  },
  mutations: {
    setShowOverlay(state, { value, message }) {
      state.showOverlay = value;
      state.overlayMessage = message || "";
    },
  },
  actions: {
    toggleOverlay({ commit }, { value, message }) {
      commit("setShowOverlay", { value, message });
    },
  },
  getters: {
    showOverlay: (state) => state.showOverlay,
    overlayMessage: (state) => state.overlayMessage,
  },
};
